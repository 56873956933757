<template>
    <div class="cr-backimg" style="background: url('https://cdn.digicollect.com/cdn/dc_admin/img/admin_login_back.png') left center no-repeat; background-size: cover; height: 100%; background-color: #fff; overflow: auto;transition:0.5s ease all">
        <div class="d-flex flex-column" style="width: 100%; min-height: 95%; height: 100%;">
            <div class="top">
                <span class="dc dc-new" style="font-size: 25px; font-weight: 600;">DigiCollect</span>
                <span class="text-default-blue" style="font-size: 20px;color: #005dae !important;margin-left: auto;font-weight: 600;"><img src="/static/images/admin_icon_blue.svg" alt="crm" style="width: 35px; height: 30px;"> DigiAdmin</span>
            </div>
            <div class="middle d-flex px-20 h-100" :style="{paddingTop: verification_link_sent_screen ? '4%' : '' }">
                <div class="middle-left"></div>
                <div v-if="verification_link_sent_screen" class="middle-right d-flex flex-column py-10" style="text-align: center;">
                    <div class="mb-10">
                        <img style="width: 190px;" src="/static/images/parrot.svg" alt="parrot" />
                    </div>
                    <p v-if="form.registration_type == 'email'" class="text-default-heading text-center mb-10" style="font-size: 18px;">A Verification link <br>has been sent to your Email Address <span class="text-default-blue">{{login_id_for_verification_screen}}</span></p>
                    <p v-else class="text-default-heading text-center mb-10" style="font-size: 18px;">A Verification link <br>has been sent to your phone number <span class="text-default-blue">{{login_id_for_verification_screen}}</span></p>
                    <span class="text-center">
                        <button class="btn btn-new-primary mb-5" @click="goToLogin">OK</button>
                    </span>
                </div>
                <div v-else class="middle-right d-flex flex-column pl-10 pr-4 ml-auto mr-auto" style="height: 100%;max-width: 700px;">
                    <span class="text-default-blue mb-5" style="font-size: 35px;">Sign up</span>
                    <span class="mb-5" style="color: #808495;">Already have an account? <span class="font-weight-bold" style="cursor: pointer; color: #005dae;" @click="$router.push('/login')">Click here</span> to Sign in</span>
                    <form action="javascript:;" @submit="signUp" autocomplete="off">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <p class="text-default-heading mb-3">Account Type<span class="star ml-1">*</span></p>
                                    <div class="d-flex align-items-center">
                                        <!-- <div class="form-check form-check-inline">
                                            <label class="form-check-label check-label-dc mr-2">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="accountType"
                                                v-validate="'required'"
                                                value="Personal"
                                                v-model="form.account_type"
                                                @click="accountTypeChanged"
                                            />
                                            <span class="radio-icon"></span>
                                            <span
                                                class="d-flex fw-600 pt-1"
                                                style="font-size: 12px !important;color: #3f3f3f;"
                                            >Personal</span>
                                            </label>
                                        </div> -->
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label check-label-dc">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="accountType"
                                                v-validate="'required'"
                                                value="Business"
                                                v-model="form.account_type"
                                                @click="accountTypeChanged"
                                            />
                                            <span class="radio-icon"></span>
                                            <span
                                                class="d-flex fw-600 pt-1"
                                                style="font-size: 12px !important;color: #3f3f3f;"
                                            >Business</span>
                                            </label>
                                        </div>
                                    </div>
                                    <span class="invalid-feedback-form text-danger mt-0" v-show="errors.has('accountType')">This field is required</span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-md-0 mt-5">
                                    <p class="text-default-heading mb-3">Register By<span class="star ml-1">*</span></p>
                                    <div class="d-flex align-items-center">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label check-label-dc mr-2">
                                        <input
                                        autocomplete="off"
                                            class="form-check-input"
                                            type="radio"
                                            name="registerBy"
                                            v-validate="'required'"
                                            value="email"
                                            v-model="form.registration_type"
                                        />
                                        <span class="radio-icon"></span>
                                        <span
                                            class="d-flex fw-600 pt-1"
                                            style="font-size: 12px !important;color: #3f3f3f;"
                                        >Email</span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label check-label-dc">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="registerBy"
                                            v-validate="'required'"
                                            value="phone"
                                            v-model="form.registration_type"
                                        />
                                        <span class="radio-icon"></span>
                                        <span
                                            class="d-flex fw-600 pt-1"
                                            style="font-size: 12px !important;color: #3f3f3f;"
                                        >Phone Number</span>
                                        </label>
                                    </div>
                                    </div>
                                    <span class="invalid-feedback-form text-danger mt-0" v-show="errors.has('registerBy')">This field is required</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <div class="position-relative">
                                        <p class="text-default-heading mb-5">First Name<span class="star ml-1">*</span></p>
                                        <input type="text" class="digi-form-full-bordered" placeholder="Enter First Name" v-model="form.first_name" name="first_name" v-validate="{required:true,regex:/^[a-zA-Z _*]+$/}" maxlength="20">
                                        <div class="d-flex justify-content-between mt-1">
                                            <span class="invalid-feedback-form text-left d-block fs-12 mt-0 font-weight-bold" style="color: #ff3b3b;" v-show="errors.has('first_name')">{{errors.first('first_name')}}</span>
                                        </div>
                                        <span class="position-absolute" style="right: 0;top: 80px;">{{(form.first_name).length}}/20</span>
                                    </div>
                                </div>
                                <div v-if="form.registration_type == 'email'" class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <div class="position-relative">
                                        <p class="text-default-heading mb-5">Email Address<span class="star ml-1">*</span></p>
                                        <input id="username" style="display:none !important;" type="email" name="fakeusernameremembered">
                                        <input autocomplete="nope" type="text" class="digi-form-full-bordered" maxlength="50" placeholder="Enter Email Address" v-model="form.email" name="email_address" v-validate="'required|email'" @blur="checkEmailPhoneExists('email')">
                                        <span class="invalid-feedback-form text-danger mt-1 font-weight-bold" style="color: #ff3b3b;" v-show="errors.has('email_address')">{{ errors.first('email_address') }}</span>
                                        <span class="invalid-feedback-form text-danger mt-1 font-weight-bold" style="color: #ff3b3b;" v-if="emailExists">Email already exists</span>
                                        <span class="position-absolute" style="right: 0;top: 80px;">{{(form.email).length}}/50</span>
                                    </div>
                                </div>
                                <div v-else class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <p class="text-default-heading mb-5">Phone Number<span class="star ml-1">*</span></p>
                                    <vue-tel-input
                                        class="teleContactborderedGrey ml-0"
                                        name="phone_number"
                                        v-model="form.phone.phone_number"
                                        v-on:country-changed="countryChanged"
                                        placeholder="Enter Phone Number"
                                        :disabledFormatting="false"
                                        :valid-characters-only="true"
                                        :disabledFetchingCountry="true"
                                        defaultCountry="IN"
                                        :maxLen="15"
                                        @onInput="phoneNumber"
                                        v-validate="{required:true, min:10}"
                                        @blur="checkEmailPhoneExists('phone')"
                                        style="width:100% !important; border-color: #cecfd0 !important;border-radius: 5px !important;"
                                    ></vue-tel-input>
                                    <span class="invalid-feedback-form mt-0 font-weight-bold" style="color: #ff3b3b;position: relative;" v-show="errors.has('phone_number')">{{ errors.first('phone_number') }}</span>
                                    <span class="invalid-feedback-form mt-0 font-weight-bold" style="color: #ff3b3b;position: relative;" v-if="phoneExists">Phone Number already exists!</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <div class="position-relative">
                                        <p class="text-default-heading mb-5">Last Name<span class="star ml-1">*</span></p>
                                        <input type="text" class="digi-form-full-bordered" placeholder="Enter Last Name" v-model="form.last_name" name="last_name" v-validate="{required:true,regex:/^[a-zA-Z _*]+$/}" maxlength="20">
                                        <div class="d-flex justify-content-between mt-1">
                                            <span class="invalid-feedback-form text-left d-block fs-12 mt-0 font-weight-bold" style="color: #ff3b3b;" v-show="errors.has('last_name')">{{errors.first('last_name')}}</span>
                                        </div>
                                        <span class="position-absolute" style="right: 0;top: 80px;">{{(form.last_name).length}}/20</span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <p class="text-default-heading mb-5">Country<span class="star ml-1">*</span></p>
                                    <div class="group-setup">
                                    <multiselect
                                        class="diginew-multiselect placeholder-country placeholder-country-login"
                                        :options="countries"
                                        :close-on-select="true"
                                        v-model="form.country"
                                        :show-labels="false"
                                        placeholder="Select Country"
                                        name="country"
                                        autocomplete="nope"
                                        v-validate="'required'"
                                    >
                                        <template slot="noOptions">
                                            <div class="text-default-blue text-center fw-600">No Country Found</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-default-blue text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                    <div class="d-flex justify-content-between mt-1">
                                        <span class="invalid-feedback-form text-left d-block fs-12 mt-0 font-weight-bold" style="color: #ff3b3b;" v-show="errors.has('country')">{{errors.first('country')}}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <p class="text-default-heading mb-5">Date of Birth</p>
                                    <date-picker
                                        :first-day-of-week="7"
                                        :not-after="new Date()"
                                        name="date_of_birth"
                                        class="dateborderGrey calendarYear register-dob-picker mx-left calendar-edit-per"
                                        format="DD/MM/YYYY"
                                        lang="en"
                                        value-type="format"
                                        :clearable="false"
                                        :editable="false"
                                        confirm
                                        confirm-text="APPLY"
                                        placeholder="DD/MM/YYYY"
                                        v-model="form.dob"
                                    >
                                    </date-picker>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <p class="text-default-heading mb-5">Gender<span class="star ml-1">*</span></p>
                                    <div class="d-flex align-items-center">
                                        <div v-if="!customGender" class="form-check form-check-inline">
                                            <label class="form-check-label check-label-dc">
                                                <input
                                                    v-if="!customGender"
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="gender"
                                                    v-validate="'required'"
                                                    value="Male"
                                                    v-model="form.gender"
                                                />
                                                <span class="radio-icon"></span>
                                                <span
                                                    class="fw-600 pt-1"
                                                    style="font-size: 12px !important;color: #3f3f3f;"
                                                >Male</span>
                                            </label>
                                        </div>
                                        <div v-if="!customGender" class="form-check form-check-inline">
                                            <label class="form-check-label check-label-dc">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="gender"
                                                    v-validate="'required'"
                                                    value="Female"
                                                    v-model="form.gender"
                                                />
                                                <span class="radio-icon"></span>
                                                <span
                                                    class="fw-600 pt-1"
                                                    style="font-size: 12px !important;color: #3f3f3f;"
                                                >Female</span>
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label check-label-dc">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    @click="customSelected"
                                                    :checked="form.gender == 'Male' || form.gender == 'Female' ? false : true"
                                                />
                                                <span class="radio-icon"></span>
                                                <span
                                                    class="d-flex fw-600 pt-1"
                                                    style="font-size: 12px !important;color: #3f3f3f;"
                                                >Custom
                                                <div>
                                                    <input v-if="customGender" name="gender" :maxlength="maxlengthcustomname" v-validate="'required'" type="text" v-model="form.custom_gender" style="margin-top: -3px; margin-left: 10px; border-bottom: 1px solid; background: transparent; width: 98%;">
                                                    <span v-if="customGender" @click.prevent="customDeselected" style="cursor: pointer; position: absolute; right: -5px; top: 4px;">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/cross.svg" height="8px">
                                                    </span>
                                                </div>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mt-1">
                                        <span class="invalid-feedback-form text-danger text-left d-block fs-12 mt-0" v-show="errors.has('gender')">{{errors.first('gender')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <p class="text-default-heading mb-5">Enter Password<span class="star ml-1">*</span></p>
                                    <div class="d-flex">
                                        <!-- <input id="password" style="display:none !important;" type="password" name="fakeusernameremembered"> -->
                                        <input v-if="!showPassword" style="padding-right: 40px !important;" id="password-auto" autocomplete="new-password" type="password" oncopy="return false" onpaste="return false" oncut="return false" @input="onPassInput" onkeydown="this.type='password'" class="digi-form-full-bordered" placeholder="Enter Password" v-model="form.password" name="password" v-validate="{required:true,min:8,regex:/^(?=.{8,})(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=!*()]).*$/}">
                                        <input v-else type="text" style="padding-right: 40px !important;" id="password-auto" autocomplete="new-password" oncopy="return false" onpaste="return false" oncut="return false" class="digi-form-full-bordered" @input="onPassInput" placeholder="Enter Password" v-model="form.password" name="password" v-validate="{required:true,min:8,regex:/^(?=.{8,})(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=()!*]).*$/}">
                                        <span v-if="!showPassword" style="margin-left: -30px; margin-top: 9px;" @click="showPassword = !showPassword">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/hide-password.svg" alt="eye" style="width: 20px;">
                                        </span>
                                        <span v-else style="margin-left: -30px; margin-top: 9px;" @click="showPassword = !showPassword">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/show-password.svg" alt="eye" style="width: 20px;">
                                        </span>
                                        <el-tooltip effect="light" placement="top">
                                            <div slot="content" style="color: #3f3f3f; font-size: 12px;">
                                                <span style="font-weight: 600;">Password must contain the following</span>
                                                <br>
                                                <span style="width:200px">
                                                    <span>
                                                        <i v-if="is_uppercase_pwd" class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                        <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                        At-least one uppercase letter (ex: A to Z)
                                                    </span><br>
                                                    <span>
                                                        <i v-if="is_number_pwd" class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                        <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                        At-least one digit (ex: 0 to 9)
                                                    </span><br>
                                                    <span>
                                                        <i v-if="is_special_character_pwd" class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                        <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                        At-least one special character (ex: $,#,@,!,%,^,&,*,(,),)<br>
                                                    </span>
                                                    <span>
                                                        <i v-if="min_pwd_length" class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                        <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                        Minimum 8 characters
                                                    </span><br>
                                                </span>
                                            </div>
                                            <i class="icon-information text-default-blue" style="font-size: 20px; margin-left: 15px; margin-top: 7px;position: absolute;right: -15px;"></i>
                                        </el-tooltip>
                                    </div>
                                    <password-meter :pwd="form.password" id="password" style="display:none"></password-meter>
                                    <span class="invalid-feedback-form font-weight-bold" style="margin-top: 4px !important;color: #ff3b3b;" v-show="errors.has('password')">{{ errors.first("password") }}</span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <p class="text-default-heading mb-5">Confirm Password<span class="star ml-1">*</span></p>
                                    <div class="d-flex">
                                    <!-- <input id="password" style="display:none !important;" type="password" name="fakepasswordremembered"> -->
                                    <input v-if="!showConfirmPassword" style="padding-right: 40px !important;" autocomplete="new-password" type="password" oncopy="return false" onpaste="return false" oncut="return false" onkeydown="this.type='password'" class="digi-form-full-bordered" placeholder="Confirm Password" v-model="confirmPassword" name="confirm_password" v-validate="'required'">
                                    <input v-else type="text" style="padding-right: 40px !important;" autocomplete="new-password" oncopy="return false" onpaste="return false" oncut="return false" class="digi-form-full-bordered" placeholder="Confirm Password" v-model="confirmPassword" name="pconfirm_passwordassword" v-validate="'required'">
                                    <span v-if="!showConfirmPassword" style="margin-left: -30px; margin-top: 9px;" @click="showConfirmPassword = !showConfirmPassword">
                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/hide-password.svg" alt="eye" style="width: 20px;">
                                    </span>
                                    <span v-else style="margin-left: -30px; margin-top: 9px;" @click="showConfirmPassword = !showConfirmPassword">
                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/show-password.svg" alt="eye" style="width: 20px;">
                                    </span>
                                    </div>
                                    <div class="d-flex justify-content-between mt-1">
                                        <span class="invalid-feedback-form text-left d-block fs-12 mt-0 font-weight-bold" style="color: #ff3b3b;" v-show="errors.has('confirm_password')">{{errors.first('confirm_password')}}</span>
                                    </div>
                                    <span class="invalid-feedback-form mt-0 font-weight-bold" style="color: #ff3b3b;" v-if="confirmPassword && confirmPassword != form.password">Passwords does not match</span>
                                </div>
                            </div>
                        <div v-if="!signedUp" class="mt-5 mb-25">
                            <button class="btn btn-new-primary mb-5" style="width: 160px !important;" type="submit">Sign up</button>
                        </div>
                        <div v-else class="mt-5 mb-25">
                            <button class="btn btn-new-primary mb-5" style="width: 160px !important;" disabled>Signing up...</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="text-right pr-4 pb-4">
                <span class="copyright text-default-blue" style="color: #005dae !important;">© Copyright 2014 - {{ new Date().getFullYear() }} DigiCollect - All Rights Reserved.</span>
            </div>
        </div>
    </div>
</template>

<script>
import countries from "../json/countries.json";
import PasswordMeter from "../PasswordMeter";
import globals from '../globals';
import auth from '../mixins/auth';
export default {
    mixins:[auth],
    data() {
        const value2date = (dateRange) => {
            const date = new Date(dateRange)
            return dateRange ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000) : null
        }
        const date2value = (date) => {
            return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : null
        }
        return {
            valueType: {
                value2date,
                date2value
            },
            form: {
                account_type: "Business",
                registration_type: "email",
                first_name: "",
                last_name: "",
                dob: "",
                gender: "Male",
                custom_gender: '',
                email: "",
                phone: {
                    country_code: "",
                    dial_code: "",
                    phone_number: "",
                    phone_type: "",
                    national: ""
                },
                country: "",
                password: "",
            },
            confirmPassword: "",
            countries: countries,
            showPassword: false,
            showConfirmPassword: false,
            signedUp: false,
            emailExists: false,
            phoneExists: false,
            customGender: false,
            maxlengthcustomname:15,
            password_regex: [],
            min_pwd_length: false,
            is_uppercase_pwd: false,
            is_number_pwd: false,
            is_special_character_pwd: false,
            only_country:[],
            user_id: '',
            verification_link_sent_screen: false,
            login_id_for_verification_screen: ''
        }
    },
    components : {
        PasswordMeter
    },
    methods: {
        phoneNumber({number,isValid,country}) {
            this.form.phone.dial_code = country.dialCode
            this.form.phone.country_code = country.iso2
            this.form.phone.national = number.national
        },
        accountTypeChanged() {
            this.form = {
                account_type: this.form.account_type,
                registration_type: "email",
                first_name: "",
                last_name: "",
                dob: "",
                gender: "Male",
                email: "",
                phone: {
                    country_code: "",
                    dial_code: "",
                    phone_number: "",
                    phone_type: "",
                    national: ""
                },
                country: "",
                password: "",
            };
            this.confirmPassword = "";
            this.showPassword = false;
            this.showConfirmPassword = false;
            this.signedUp = false;
            this.emailExists = false;
            this.phoneExists = false;
            this.customGender = false;
            this.$nextTick(() => {
                this.errors.clear();
                this.$nextTick(() => {
                    this.$validator.reset();
                });
            });
            setTimeout(() => {
                this.country = ""
                this.password = ""
            }, 1000);
        },
        async checkEmailPhoneExists(type) {
            let params = {};
            if (type == "email") {
                params = {
                    login_id: this.form.email,
                };
            } else {
                let phone_number = {...this.form.phone};
                params = {
                    login_id: phone_number.national,
                };
            }
            if (params.login_id) {
                let res = await this.checkEmailPhoneExistsOrNot(params);
                if (res.exists) {
                    if (type == "email") {
                        this.emailExists = true;
                    } else {
                        this.phoneExists = true;
                    }
                } else {
                    if (type == "email") {
                        this.emailExists = false;
                    } else {
                        this.phoneExists = false;
                    }
                }
            }
        },
        onPassInput(e) {
            if(this.form.password){
                $('#password').slideDown('slow')
                document.getElementById("password").style.display = "flex"
            }
            else{
                $('#password').slideUp('slow')
                document.getElementById("password").style.display = "none"
            }
            if(e.target.value.match(/^(?=.{8,}$)/)) {
                this.min_pwd_length = true;
            }
            else {
                this.min_pwd_length = false;
            }
            if(e.target.value.match(/^(?=.*[A-Z])/)) {
                this.is_uppercase_pwd = true;
            } else {
                this.is_uppercase_pwd = false;
            }
            if(e.target.value.match(/^(?=.*[0-9])/)) {
                this.is_number_pwd = true;
            } else {
                this.is_number_pwd = false;
            }
            if(e.target.value.match(/^(?=.*[!@#$%^&*()])/)) {
                this.is_special_character_pwd = true;
            } else {
                this.is_special_character_pwd = false;
            }
        },
        signUp() {
            this.$validator.validateAll().then(result => {
                if (result && !this.emailExists && this.confirmPassword === this.form.password) {
                    this.registerUser();
                }
            });
        },
        countryChanged(country) {
            this.form.phone = {
                phone_number: "",
                phone_type: "primary",
                dial_code: country.dialCode,
                country_code: country.iso2,
            };
        },
        async registerUser() {
            try {
                this.signedUp = true;
                let params = {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    password: this.form.password,
                    registration_type: this.form.registration_type,
                    country: this.form.country,
                    gender: this.form.gender,
                    date_of_birth: this.form.dob,
                    account_type: this.form.account_type,
                    custom_gender: this.form.custom_gender
                };
                if (!params.date_of_birth) {
                    delete params["date_of_birth"];
                } else {
                    let fulldate = params.date_of_birth;
                    params.date_of_birth = fulldate.split('/')[2]+'-'+fulldate.split('/')[1]+'-'+fulldate.split('/')[0];
                }
                if (this.form.registration_type == "email") {
                    Object.assign(params, {
                        login_id: this.form.email,
                    });
                } else {
                    Object.assign(params, {
                        login_id: {...this.form.phone},
                    });
                    let phone_number = {...this.form.phone};
                    params.login_id.phone_number = phone_number.national;
                    delete params.login_id.phone_number['national']
                }
                let res = await this.register(params);
                if (res.status_id == 1) {
                    this.user_id = res.user_id;
                    this.verification_link_sent_screen = true;
                    this.login_id_for_verification_screen = res.login_id;
                } else {
                    this.$swal({
                        title: "",
                        html: 'Not Able to Register. Please Contact ' +
                            '<a href="mailto:helpdesk@digicollect.com" style="color: #0d84f2">DigiCollect Helpdesk</a>',
                        type: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#0475f3",
                    });
                }
                this.signedUp = false;
            }
            catch(err) {
                this.signedUp = false;
            }
        },
        customSelected() {
            if (!this.customGender) {
                this.form.gender = "custom";
                this.customGender = true;
            }
        },
        customDeselected() {
            if (this.customGender) {
                this.customGender = false;
                this.form.gender = "Male";
            }
        },
        goToLogin() {
            window.location.href = globals.ADMIN_URL+'/login';
        }
    },
    mounted() {
        setTimeout(() => {
            this.form.email = "";
            this.form.password = "";
            this.errors.clear();
            this.$validator.reset();
            $('#password').slideUp('slow')
            document.getElementById("password").style.display = "none"
        }, 1000);

        setTimeout(() => {
            // let tagArr = document.getElementByID("password-auto");
            // for (let i = 0; i < tagArr.length; i++) {
            //     tagArr[i].autocomplete = 'off';
            // }
            $(document).ready(function() {
                $('#password-auto').attr('autocomplete', 'off');
                setTimeout(() => {
                    this.emailExists = false
                }, 1000);
            });
        }, 500);
        // $("#password-auto").autocomplete({
        //     disabled: true
        // });
    },
    created() {
        const dict = {
            custom: {
                first_name: {
                    required: () => "First Name is required",
                    regex: () => "Enter a Valid Name",
                },
                last_name: {
                    required: () => "Last Name is required",
                    regex: () => "Enter a Valid Name",
                },
                gender: {
                    required: () => "Gender is required",
                },
                email_address: {
                    required: () => "Email Address is required",
                    email: () => "Please enter valid Email Address",
                },
                // business_email:{
                //     required: () => "Business Email Address is required",
                //     email: () => "Please enter valid Email Address",
                // },
                phone_number: {
                    required: () => "Phone Number is required",
                    min: () => "Phone Number must contain more than 9 numbers",
                },
                country:{
                    required: () => "Country is required",
                },
                confirm_password:{
                    required: () => "Confirm Password is required",
                },
                password: {
                    required: () => "Password is required",
                    min: () => "Password must contain minimum 8 characters",
                    regex: () => "Password is Invalid",
                },
            }
        };
        this.$validator.localize("en", dict);
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()".split('').forEach(x => { this.password_regex.push(x) });
    },
}
</script>

<style scoped>
    .image-holder {
        width: 500px;
        background: #fff;
        height: 500px;
        border-radius: 50%;
        bottom: -90px;
        position: absolute;
        left: -60px;
    }
    .group-image {
        width: 350px;
        height: 350px;
        right: 60px;
        position: absolute;
        top: 60px;
    }
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-left: 60px;
        padding-right: 60px;
    }
    .middle-left {
        width: 50%;
    }
    .middle-right {
        width: 50%;
    }
    .star {
        font-size: 12px;
        color: #F44336;
    }
    @media (max-width: 1290px) {
        .middle {
            margin-top: 30px;
        }
        .middle-left {
            display: none;
        }
        .middle-right {
            width: 100%;
            max-height: 100%;
            justify-content: center;
        }
        .cr-backimg {
            background: #FFF !important;
        }
        .dc-new {
            display: block;
        }
    }
    @media screen and (min-width: 741px) {
        .crm-right-logo {
            margin-left: auto;
        }
    }
    @media (max-width: 740px) {
        .middle {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .middle-right {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
        .top {
            justify-content: center;
            flex-direction: column;
        }
    }
    #password{
        transition: 0.5s ease all;
    }
    .text-default-heading {
        font-weight: 600;
    }
</style>